import { get, post } from '@/service/request';
import { dateFormat } from '@/filter';
import { ElevatorAlarmTrendQueryModel } from '../model/elevator-alarm-trend';

const URL_PATH = `${BIBIZ_BASE_REQUEST_PATH}/elevatorSystem`;
class ElevatorIndexService {
    /**
     * 查询告警数量
     * @returns
     */
    async getAlarmCount() {
        const res = await post(`${URL_PATH}/alarm/hts/ACTIVE/count`);
        return res;
    }

    /**
     * 设备总数/异常设备
     * @returns
     */
    async getFacilityCount() {
        const url = `${URL_PATH}/facility/rts/count`;
        const res = await get(url);
        return res;
    }

    /**
     * 设备状态统计
     * @returns
     */
    async getFacilityStatusCount() {
        const url = `${URL_PATH}/facility/rts/status/count`;
        const res = await get(url);
        return {
            columns: ['name', 'value'],
            rows: res
        };
    }

    /**
     * 告警状态统计
     * @returns
     */
    async getAlarmStatusCount() {
        const url = `${URL_PATH}/alarm/rts/STATUS/count`;
        const res = await get(url);
        return {
            columns: ['name', 'value'],
            rows: [
                { name: '已处理', value: res.solvedCount },
                { name: '未处理', value: res.unsolvedCount }
            ]
        };
    }

    /**
     * 设备空间分布
     * @returns
     */
    async getFacilityLocationCount() {
        const url = `${URL_PATH}/facility/rts/location/count`;
        const res = await get(url);
        return {
            columns: ['name', '数量'],
            rows: _.map(res, item => ({ name: item.locationName, '数量': item.count }))
        };
    }

    /**
     * 近7天告警趋势
     * @returns
     */
    async getLast7DayAlarmCount(model: ElevatorAlarmTrendQueryModel) {
        const url = `${URL_PATH}/alarm/hts/countTrends`;
        const res = await post(url, model.toService());
        return {
            columns: ['ts', '数量'],
            rows: _.map(res, item => {
                return {
                    ts: dateFormat(item.ts, 'MM-DD'),
                    '数量': item.value
                };
            })
        };
    }
}

export default new ElevatorIndexService();
